import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Task = () => {
    const [tasks, setTasks] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get('https://supremecarsluton.co.uk/api/tasks/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTasks(response.data);
        } catch (error) {
            console.error('Error fetching tasks', error);
        }
    };

    const createTask = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('https://supremecarsluton.co.uk/api/tasks/', {
                title,
                description,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTasks([...tasks, response.data]);
            setTitle('');
            setDescription('');
        } catch (error) {
            console.error('Error creating task', error);
        }
    };

    const updateTask = async (id, completed) => {
        const token = localStorage.getItem('token');
        try {
            await axios.patch(`https://supremecarsluton.co.uk/api/tasks/${id}/`, {
                completed,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchTasks();
        } catch (error) {
            console.error('Error updating task', error);
        }
    };

    const deleteTask = async (id) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`https://supremecarsluton.co.uk/api/tasks/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchTasks();
        } catch (error) {
            console.error('Error deleting task', error);
        }
    };

    return (
        <div className="container">
            <h2 className="my-4">Tasks</h2>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <button className="btn btn-success w-100" onClick={createTask}>Add Task</button>
            </div>
            <ul className="list-group">
                {tasks.map((task) => (
                    <li key={task.id} className="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                            <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={task.completed}
                                onChange={() => updateTask(task.id, !task.completed)}
                            />
                            <span>{task.title}</span>
                        </div>
                        <button className="btn btn-danger btn-sm" onClick={() => deleteTask(task.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Task;
