import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://supremecarsluton.co.uk/api/login/', {
                username,
                password,
            });
            localStorage.setItem('token', response.data.access);
            navigate('/dashboard'); // Redirect to dashboard after successful login
        } catch (error) {
            console.error('Error logging in', error);
            alert('Login failed. Please check your credentials.');
        }
    };

    const handleSignup = () => {
        navigate('/signup'); // Redirect to signup page
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h1 className="text-center my-4">Login</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Username:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Password:</label>
                            <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">Login</button>
                    </form>
                    <div className="text-center mt-3">
                        <button className="btn btn-secondary" onClick={handleSignup}>Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
