import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Task from './Task';
import 'bootstrap/dist/css/bootstrap.min.css';

const Dashboard = () => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login'); // Redirect to login if no token is found
                return;
            }

            try {
                const response = await axios.get('https://supremecarsluton.co.uk/api/dashboard/', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setMessage(response.data.message);
            } catch (error) {
                console.error('Error fetching dashboard data', error);
            }
        };

        fetchData();
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div className="container">
            <h1 className="text-center my-4">Dashboard</h1>
            <p className="text-center">{message}</p>
            <button className="btn btn-danger w-100 mb-4" onClick={handleLogout}>Logout</button>
            <Task />
        </div>
    );
};

export default Dashboard;
